import { Injectable } from '@angular/core';
import { httpOptions } from '../../configurations/configuration';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {


  constructor(private http: HttpClient) { }

  getOrder( id: any) : Observable<any> {
    if(id){

    }
    else {
        id = 1;
    }
    return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getOrderUrl+"/" + id);
  }

  getAllOrder(filterObj,start,limit){
      let urlGetAllOrder = environment.apiURL + environment.adminApiUrl + environment.getOrderUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllOrder)
  }

  deleteOrder(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteOrderUrl, postObj,httpOptions);
  }
}
